import { type Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class ManagedFleetsOverviewWidget extends BaseWidget<never> {
  static readonly id = 'managed-fleets-overview';
  static readonly publicFriendly = false;

  static factory(injector: Injector, metaLinkId: string, x: number, y: number, rows: number, cols: number) {
    return new ManagedFleetsOverviewWidget(injector, metaLinkId, x, y, rows, cols);
  }

  constructor(injector: Injector, metaLinkId: string, x: number, y: number, rows: number, cols: number) {
    super(
      injector,
      ManagedFleetsOverviewWidget.id,
      ManagedFleetsOverviewWidget.publicFriendly,
      metaLinkId,
      x,
      y,
      rows,
      cols
    );
    this.resolveComponent(async () => {
      const module = await import('./managed-fleets-overview.component');
      return Object.values(module)[0];
    });
  }
}
